.search-form {
  background: rgba(92, 48, 94, 0.1);
  padding: 16px; /* Reduce padding inside the form */
  border-radius: 12px; /* Slightly smaller rounded corners */
  border: 1px solid rgba(247, 0, 255, 0.1);
  box-shadow: 0 4px 16px rgba(247, 0, 255, 0.15); /* Softer shadow */
  max-width: 500px; /* Set a smaller max width */
  margin: 0 auto 16px; /* Center the form and reduce bottom margin */
  backdrop-filter: blur(8px); /* Adjust blur for a cleaner look */
}

.search-form h3 {
  margin-bottom: 16px; /* Smaller space below title */
  font-size: 1.2em; /* Slightly smaller title */
  color: #FFFFFF;
  font-weight: 600;
  text-align: center; /* Center-align the title */
}

.city-search,
.current-location {
  display: flex;
  flex-direction: column;
  gap: 10px; /* Reduce gaps between inputs/buttons */
  margin-bottom: 12px; /* Reduce space below sections */
}

.city-search label {
  font-size: 0.8em; /* Smaller label text */
  font-weight: 500;
  color: #FFFFFF;
  margin-bottom: 6px;
}

.city-search input {
  width: 100%;
  max-width: 400px;
  margin: 0 auto;
  padding: 8px 10px; /* Smaller padding for inputs */
  font-size: 0.9em; /* Smaller input text size */
  background: rgba(255, 255, 255, 0.1);
  border: 1px solid #5C305E;
  border-radius: 6px; /* Slightly sharper corners */
  color: #FFFFFF;
  transition: all 0.3s ease;
}

.city-search input:focus {
  outline: none;
  border-color: #F700FF;
  box-shadow: 0 0 0 2px rgba(247, 0, 255, 0.2);
}

.search-city-btn,
.current-location-btn,
.confirm-btn,
.search-btn,
.refresh-btn {
  padding: 6px 12px; /* Smaller button size */
  font-size: 0.9em; /* Smaller button text size */
  font-weight: 500;
  color: #FFFFFF;
  background: linear-gradient(135deg, #5C305E, #1A1A1A);
  border: none;
  border-radius: 6px; /* Slightly sharper corners */
  cursor: pointer;
  text-align: center;
  transition: all 0.3s ease;
}

.search-city-btn:hover,
.current-location-btn:hover,
.confirm-btn:hover,
.search-btn:hover,
.refresh-btn:hover {
  background: linear-gradient(135deg, #333, #222); /* Hover effect */
}

.coordinates-info {
  margin-top: 12px;
  padding: 10px;
  background: rgba(26, 26, 26, 0.2);
  border-radius: 8px;
  border: 1px solid rgba(247, 0, 255, 0.1);
  color: #FFFFFF;
  font-size: 0.85em; /* Slightly smaller text */
  text-align: center;
}

.confirmation-section {
  display: flex;
  justify-content: space-between; /* Align buttons horizontally */
  align-items: center;
  margin-top: 16px;
  gap: 10px;
  flex-wrap: wrap; /* Allow wrapping for smaller screens */
}

.error-message {
  color: #FF0000;
  font-size: 0.85em; /* Smaller error message font */
  margin-top: 10px;
  font-weight: bold;
  text-align: center;
}


