/* Header styles */
header {
  background-size: cover;
  color: #FFFFFF;
  padding: 16px 50px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  position: relative;
  height: 80px;

}


.header-left {
  display: flex;
  align-items: center;
  gap: 16px;
}

.header-logo {
  height: 60px;
  filter: drop-shadow(0 0 5px rgba(247, 0, 255, 0.3));
  transition: transform 0.3s ease;
}

.header-logo:hover {
  transform: scale(1.05);
}

.site-title {
  font-family: 'Montserrat', sans-serif;
  color: #FFFFFF;
  font-size: 2.1em;
  font-weight: 600;
  margin: 0;
  margin-left: 15px;
  -webkit-background-clip: text;
  background-clip: text;
  text-align: center;
  letter-spacing: 0.6em;
}




/* From Uiverse.io by rraushan1205 */ 
/* === removing default button style ===*/
.nav-link {
  margin: 0;
  margin-left: 10px;
  height: auto;
  background: transparent;
  padding: 0;
  border: none;
}

/* button styling */
.nav-link {
  --border-right: 6px;
  --text-stroke-color: #ffffff;
  --animation-color: #FFFFFF;
  --fs-size: 1em;
  letter-spacing: 5px;
  text-decoration: none;
  font-size: var(--fs-size);
  font-family: "Montserrat";
  position: relative;
  text-transform: uppercase;
  color: transparent;
  -webkit-text-stroke: 1px var(--text-stroke-color);
}
/* this is the text, when you hover on button */
.hover-text {
  position: absolute;
  box-sizing: border-box;
  content: attr(data-text);
  color: var(--animation-color);
  width: 0%;
  inset: 0;
  border-right: var(--border-right) solid var(--animation-color);
  overflow: hidden;
  transition: 0.5s;
  -webkit-text-stroke: 1px var(--animation-color);
}
/* hover */
.nav-link:hover .hover-text {
  width: 100%;
  filter: drop-shadow(0 5px 23px var(--animation-color))
}

@media (max-width: 768px) {
  header {
    padding: 12px 24px; /* Slightly larger padding for medium screens */
    height: auto; /* Allow height to adjust dynamically */
    display: flex;
    flex-direction: column; /* Stack elements vertically */
    align-items: center; /* Center-align all items */
    justify-content: center; /* Center content */
    gap: 12px; /* Add more space between elements */
  }

  .header-logo {
    height: 40px; /* Slightly larger logo size */
    margin-bottom: 12px; /* Add spacing below logo */
  }

  .site-title {
    font-size: 1.2em; /* Adjust font size for medium screens */
    text-align: center; /* Center-align the title */
    margin-bottom: 16px; /* Add more spacing below the title */
  }

  .nav-links {
    display: flex;
    flex-wrap: wrap; /* Allow buttons to wrap to the next line if needed */
    gap: 16px; /* Add more space between buttons */
    justify-content: center; /* Center-align buttons */
    font-size: 1em; /* Adjust button font size */
    width: 100%; /* Ensure navigation spans full width */
  }

  .nav-link {
    padding: 8px 16px; /* Adjust padding for buttons */
    font-size: 1em; /* Slightly larger font for better readability */
    border-radius: 8px; /* Keep rounded corners for buttons */
    background: rgba(92, 48, 94, 0.2); /* Subtle background for visibility */
    color: #FFFFFF;
    text-decoration: none;
    transition: background 0.3s ease;
    text-align: center;
  }

  .nav-link:hover {
    background: rgba(92, 48, 94, 0.4); /* Highlight on hover */
  }
}


@media (max-width: 480px) {
  .header-logo {
    height: 80px;
  }

  .site-title {
    font-size: 1.6em;
  }

  .nav-link {
    font-size: 1em;
  }
}

@media (max-width: 320px) {
  header {
    padding: 8px 16px;
    height: auto; /* Allow the height to adjust dynamically */
    display: flex;
    flex-direction: column; /* Stack elements vertically if needed */
    align-items: center; /* Center-align all items */
    justify-content: center; /* Center content */
    gap: 8px; /* Add space between elements */
  }

  .header-logo {
    height: 20px; /* Adjust logo size */
    margin-bottom: 4px; /* Add spacing below logo */
  }

  .site-title {
    font-size: 0.8em;
    text-align: center; /* Center-align the title */
    margin-bottom: 8px; /* Add spacing below the title */
  }

  .nav-links {
    display: flex;
    flex-wrap: wrap; /* Allow buttons to wrap to the next line if needed */
    gap: 8px; /* Add space between buttons */
    justify-content: center; /* Center-align buttons */
    font-size: 0.8em; /* Adjust button font size */
  }

  .nav-link {
    padding: 6px 12px; /* Reduce padding for smaller buttons */
    border-radius: 6px; /* Keep buttons rounded */
    background: rgba(92, 48, 94, 0.2); /* Add subtle background for visibility */
    color: #FFFFFF;
    text-decoration: none;
    transition: background 0.3s ease;
  }

  .nav-link:hover {
    background: rgba(92, 48, 94, 0.4); /* Highlight on hover */
  }
}

@media (max-width: 375px) {
  header {
    padding: 4px 8px; /* Smaller padding for mobile */
    height: auto;
    display: flex;
    flex-direction: column; /* Stack elements vertically */
    align-items: center;
    justify-content: center;
    gap: 8px; /* Space between elements */
    max-height: 120px; /* Limit height */
    overflow: hidden; /* Hide overflowing content */
  }

  .header-logo {
    height: 20px; /* Smaller logo */
    margin-bottom: 4px;
  }

  .site-title {
    font-size: 0.2em; /* Smaller title */
    text-align: center;
    margin-bottom: 8px;
  }

  .nav-links {
    display: flex;
    flex-wrap: wrap; /* Allow buttons to wrap */
    gap: 8px;
    justify-content: center; /* Center-align buttons */
    font-size: 0.8em;
    width: 100%; /* Full-width for navigation */
    text-align: center;
  }

  .nav-link {
    padding: 2px 4px; /* Smaller buttons */
    font-size: 0.7em; /* Smaller font size */
    border-radius: 4px; /* Smaller rounded corners */
    background: rgba(92, 48, 94, 0.2); /* Subtle background */
    color: #FFFFFF;
    text-decoration: none;
    transition: background 0.3s ease;
    text-align: center;
  }

  .nav-link:hover {
    background: rgba(92, 48, 94, 0.4); /* Highlight on hover */
  }
}

@media (max-width: 430px) {
  header {
    padding: 8px 16px; /* Smaller padding for mobile */
    height: auto; /* Allow height to adjust dynamically */
    display: flex;
    flex-direction: column; /* Stack elements vertically */
    align-items: center;
    justify-content: center;
    gap: 8px; /* Add space between elements */
  }

  .header-logo {
    height: 30px; /* Adjust logo size for iPhone 11 */
    margin-bottom: 8px; /* Add spacing below logo */
  }

  .site-title {
    font-size: 1em; /* Adjust font size for title */
    text-align: center; /* Center-align the title */
    margin-bottom: 12px; /* Add spacing below the title */
  }

  .nav-links {
    display: flex;
    flex-wrap: wrap; /* Allow buttons to wrap to the next line if needed */
    gap: 12px; /* Add space between buttons */
    justify-content: center; /* Center-align buttons */
    font-size: 0.9em; /* Adjust button font size */
    width: 100%; /* Ensure navigation spans full width */
  }

  .nav-link {
    padding: 6px 14px; /* Adjust padding for buttons */
    font-size: 0.9em; /* Slightly larger font for visibility */
    border-radius: 8px; /* Rounded corners for buttons */
    background: rgba(92, 48, 94, 0.2); /* Subtle background for visibility */
    color: #FFFFFF;
    text-decoration: none;
    transition: background 0.3s ease;
    text-align: center;
  }

  .nav-link:hover {
    background: rgba(92, 48, 94, 0.4); /* Highlight on hover */
  }
}


