.about-page {
    max-width: 1000px;
    margin: 0 auto;
    padding: 32px;
    background: rgba(92, 48, 94, 0.1);
    border-radius: 16px;
    box-shadow: 0 8px 32px rgba(247, 0, 255, 0.15);
  }
  
  .about-page h1 {
    text-align: center;
    font-size: 2em;
    font-weight: 600;
    margin-bottom: 32px;
    background: transparent;
  }
  
  .about-page h2 {
    font-size: 2em;
    color: #c229ca;
    margin-bottom: 16px;
  }
  
  .about-page p {
    font-size: 1.2em;
    color: #FFFFFF;
    line-height: 1.6;
  }
  
  .about-section, .non-profit, .motivation, .call-to-action {
    background: rgba(26, 26, 26, 0.4);
    border-radius: 12px;
    padding: 24px;
    margin-bottom: 32px;
    border: 1px solid rgba(247, 0, 255, 0.1);
  }
  
  .call-to-action {
    text-align: center;
    background: rgba(92, 48, 94, 0.2);
  }
  
  @media (max-width: 768px) {
    .about-page {
      padding: 20px;
    }
  
    .about-page h1 {
      font-size: 2.4em;
    }
  
    .about-page h2 {
      font-size: 1.8em;
    }
  
    .about-page p {
      font-size: 1.1em;
    }
  }
  
  @media (max-width: 320px) {
    .about-page {
      padding: 16px;
    }
  
    .about-page h1 {
      font-size: 1.5em;
    }
  
    .about-page h2 {
      font-size: 1.2em;
    }
  
    .about-page p {
      font-size: 0.9em;
    }
  
    .about-section, .non-profit, .motivation, .call-to-action {
      padding: 16px;
    }
  }
  
  @media (max-width: 375px) {
    .about-page {
      padding: 20px;
    }
  
    .about-page h1 {
      font-size: 1.8em;
    }
  
    .about-page h2 {
      font-size: 1.5em;
    }
  
    .about-page p {
      font-size: 1em;
    }
  
    .about-section, .non-profit, .motivation, .call-to-action {
      padding: 20px;
    }
  }
  