.satellite-list-container {
  padding: 32px;
  background: rgba(92, 48, 94, 0.1);
  border-radius: 16px;
  box-shadow: 0 8px 32px rgba(247, 0, 255, 0.1);
  margin: 32px 0;
  border: 1px solid rgba(247, 0, 255, 0.1);
  backdrop-filter: blur(12px);
  position: relative; /* Allows positioning of the info button */
}

.satellite-list-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 32px;
}

.satellite-list-header h2 {
  color: #FFFFFF;
  font-size: 2.2em;
  font-weight: 600;
  letter-spacing: -0.02em;
  text-align: center;
}

.satellite-item {
  margin: 16px 0;
  padding: 24px;
  background: rgba(26, 26, 26, 0.6);
  border-radius: 12px;
  border: 1px solid rgba(247, 0, 255, 0.1);
  transition: all 0.3s ease;
  position: relative;
  overflow: hidden;
}

.satellite-item:hover {
  transform: translateY(-4px);
  border-color: rgba(247, 0, 255, 0.3);
  box-shadow: 0 8px 24px rgba(92, 48, 94, 0.2);
}

.satellite-item::before {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  width: 4px;
  height: 100%;
  background: linear-gradient(to bottom, #F700FF, #5C305E);
  opacity: 0;
  transition: opacity 0.3s ease;
}

.satellite-item:hover::before {
  opacity: 1;
}

.satellite-item h3 {
  margin: 0 0 12px 0;
  color: #FFFFFF;
  font-size: 1.4em;
  font-weight: 600;
  letter-spacing: -0.01em;
}

.satellite-item p {
  margin: 8px 0;
  color: rgba(255, 255, 255, 0.8);
  line-height: 1.6;
  font-size: 1.1em;
}

/* Info Button Styling */
.info-button {
  position: absolute;
  top: 16px;
  right: 16px;
  background-color: rgba(247, 0, 255, 0.2);
  border: 1px solid rgba(247, 0, 255, 0.4);
  color: white;
  border-radius: 8px;
  padding: 6px 12px;
  cursor: pointer;
  font-size: 14px;
  transition: all 0.3s ease;
  z-index: 10;
}

.info-button:hover {
  background-color: rgba(247, 0, 255, 0.4);
  box-shadow: 0 4px 12px rgba(247, 0, 255, 0.2);
}

/* Modal Styles */
.info-modal {
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 80%;
  max-width: 500px;
  background-color: rgba(26, 26, 26, 0.95);
  padding: 24px;
  border-radius: 12px;
  box-shadow: 0 8px 24px rgba(0, 0, 0, 0.3);
  color: white;
  text-align: center;
}

.modal-overlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.7);
  z-index: 999;
}

.close-button {
  background-color: transparent;
  border: none;
  color: white;
  font-size: 20px;
  cursor: pointer;
  position: absolute;
  top: 10px;
  right: 10px;
}

.close-button:hover {
  color: rgba(247, 0, 255, 0.7);
}



/* Responsive Styles */
@media (max-width: 768px) {
  .satellite-list-container {
    padding: 20px;
  }

  .satellite-item {
    padding: 20px;
  }

  .satellite-item h3 {
    font-size: 1.2em;
  }

  .satellite-item p {
    font-size: 1em;
  }
}

@media (max-width: 320px) {
  .satellite-list-container {
    padding: 16px;
  }

  .satellite-list-header h2 {
    font-size: 1.5em;
  }

  .satellite-item h3 {
    font-size: 1em;
  }

  .satellite-item p {
    font-size: 0.8em;
  }

  .info-button {
    padding: 4px 8px;
    font-size: 12px;
  }
}

@media (max-width: 375px) {
  .satellite-list-container {
    padding: 20px;
  }

  .satellite-list-header h2 {
    font-size: 1.6em;
  }

  .satellite-item h3 {
    font-size: 1.2em;
  }

  .satellite-item p {
    font-size: 0.9em;
  }

  .info-button {
    padding: 6px 12px;
    font-size: 12px;
  }
}
