.satellite-details {
  max-width: 1000px;
  margin: 32px auto;
  padding: 32px;
  background: rgba(92, 48, 94, 0.1);
  border-radius: 16px;
  border: 1px solid rgba(247, 0, 255, 0.1);
  backdrop-filter: blur(12px);
  box-shadow: 0 8px 32px rgba(247, 0, 255, 0.15);
  display: flex;
  flex-direction: column;
}

.main-content {
  display: flex;
  gap: 32px;
  margin-bottom: 32px;
}

.left-section {
  flex: 1;
}

.title-section {
  text-align: center;
  margin-bottom: 32px;
}

.satellite-details h2 {
  text-align: center;
  font-size: 2.2em;
  margin-bottom: 32px;
  font-weight: 600;
  letter-spacing: -0.02em;
  position: relative;
}

.satellite-details h2::after {
  content: '';
  position: absolute;
  bottom: -12px;
  left: 50%;
  transform: translateX(-50%);
  width: 60px;
  height: 4px;
  background: linear-gradient(90deg, #F700FF, #5C305E);
  border-radius: 2px;
}

.satellite-info {
  flex: 1; /* Add this line to make it take remaining space */
  background: rgba(26, 26, 26, 0.4);
  border-radius: 12px;
  padding: 24px;
  border: 1px solid rgba(247, 0, 255, 0.1);
  color: #FFFFFF;
}


.satellite-info p {
  font-size: 1.2em;
  margin: 12px 0;
  line-height: 1.6;
}

.compass-section {
  flex-shrink: 0;
  width: 40%;
}

.compass-container {
  flex-shrink: 0; /* Add this to prevent shrinking */
  padding: 24px;
  background: rgba(92, 48, 94, 0.2);
  border-radius: 12px;
  border: 1px solid rgba(247, 0, 255, 0.2);
  transition: transform 0.3s ease;
  display: flex;
  justify-content: center;
  align-items: center;
}

.compass-image {
  width: 220px;
  height: 220px;
  object-fit: contain;
  border-radius: 8px;
  filter: drop-shadow(0 4px 8px rgba(247, 0, 255, 0.2));
}

.visual-passes-section {
  margin-top: 32px;
  padding: 24px;
  background: rgba(26, 26, 26, 0.4);
  border-radius: 12px;
  border: 1px solid rgba(247, 0, 255, 0.1);
}

.pass-item {
  padding: 20px;
  margin: 16px 0;
  background: rgba(92, 48, 94, 0.2);
  border-radius: 12px;
  border: 1px solid rgba(247, 0, 255, 0.1);
  line-height: 1.6;
  color: #FFFFFF;
  transition: all 0.3s ease;
}

.map-btn {
  padding: 14px 28px; /* Keep the original padding */
  font-size: 1.1em; /* Keep the original font size */
  font-weight: 500; /* Maintain consistency */
  font-family: 'Montserrat', sans-serif;
  color: #FFFFFF;
  background: #ac2ca6;
  border: none;
  border-radius: 8px; /* Keep the original border radius */
  cursor: pointer;
  text-align: center;
  transition: all 0.3s ease; /* Keep smooth transitions */
  display: inline-flex; /* Align SVG and text properly */
  align-items: center; /* Vertically center SVG and text */
}

.map-btn:hover {
  background: linear-gradient(135deg, #333, #222); /* Add hover effect */
}

.map-btn svg {
  display: inline;
  width: 1.3rem; /* Same as in .button */
  height: 1.3rem; /* Same as in .button */
  margin-right: 0.75rem; /* Keep spacing for the icon */
  color: white;
}

.map-btn:focus svg {
  animation: spin_357 0.5s linear; /* Add spinning animation on focus */
}

.modal-map {
  position: relative;
  top: 50%;
  left: 50%;
  right: auto;
  bottom: auto;
  transform: translate(-50%, -50%);
  background: rgba(26, 26, 26, 0.95);
  border: 1px solid rgba(247, 0, 255, 0.2);
  border-radius: 16px;
  padding: 24px;
  width: 90%;
  max-width: 1200px;
  height: 80vh;
  box-shadow: 0 12px 48px rgba(247, 0, 255, 0.3);
}

.modal-overlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: rgba(26, 26, 26, 0.85);
  backdrop-filter: blur(8px);
  z-index: 1000;
}

.close-button {
  position: absolute;
  right: 16px;
  top: 16px;
  background: rgba(92, 48, 94, 0.3);
  border: 1px solid rgba(247, 0, 255, 0.2);
  border-radius: 50%;
  width: 40px;
  height: 40px;
  font-size: 24px;
  color: #FFFFFF;
  cursor: pointer;
  z-index: 1001;
  display: flex;
  justify-content: center;
  align-items: center;
  transition: all 0.3s ease;
}

.close-button:hover {
  background: rgba(247, 0, 255, 0.3);
  transform: rotate(90deg);
}

.pass-section {
  background: rgba(26, 26, 26, 0.4);
  border-radius: 12px;
  margin-bottom: 24px;
  border: 1px solid rgba(247, 0, 255, 0.1);
  overflow: hidden;
}

.pass-header {
  background: rgba(92, 48, 94, 0.3);
  padding: 12px 20px;
  font-size: 1.2em;
  font-weight: 500;
  color: #F700FF;
  border-bottom: 1px solid rgba(247, 0, 255, 0.1);
}

.pass-content {
  padding: 20px;
}

.info-pair {
  display: flex;
  margin-bottom: 12px;
  align-items: baseline;
}

.info-label {
  color: rgba(255, 255, 255, 0.7);
  min-width: 100px;
  font-size: 1em;
}

.info-value {
  color: #FFFFFF;
  font-size: 1.1em;
  flex: 1;
}

.pass-summary {
  background: rgba(26, 26, 26, 0.4);
  border-radius: 12px;
  padding: 20px;
  margin-top: 32px;
  border: 1px solid rgba(247, 0, 255, 0.1);
  display: flex;
  gap: 32px;
}

.info-icon {
  cursor: pointer;
  color: #F700FF;
  margin-left: 8px;
  font-size: 18px;
}

.info-modal {
  padding: 20px;
  background-color: rgba(26, 26, 26, 0.9);
  border-radius: 12px;
  color: #fff;
  max-width: 600px;
  margin: 50px auto;
}

.loading-container {
  display: flex;
  justify-content: center;
  align-items: center;
  min-height: 100vh;
  background: rgba(92, 48, 94, 0.1);
  backdrop-filter: blur(12px);
}

.three-body {
  --uib-size: 35px;
  --uib-speed: 0.8s;
  --uib-color: #F700FF;
  position: relative;
  display: inline-block;
  height: var(--uib-size);
  width: var(--uib-size);
  animation: spin78236 calc(var(--uib-speed) * 2.5) infinite linear;
}

.three-body__dot {
  position: absolute;
  height: 100%;
  width: 30%;
}

.three-body__dot:after {
  content: '';
  position: absolute;
  height: 0%;
  width: 100%;
  padding-bottom: 100%;
  background-color: var(--uib-color);
  border-radius: 50%;
}

.three-body__dot:nth-child(1) {
  bottom: 5%;
  left: 0;
  transform: rotate(60deg);
  transform-origin: 50% 85%;
}

.three-body__dot:nth-child(1)::after {
  bottom: 0;
  left: 0;
  animation: wobble1 var(--uib-speed) infinite ease-in-out;
  animation-delay: calc(var(--uib-speed) * -0.3);
}

.three-body__dot:nth-child(2) {
  bottom: 5%;
  right: 0;
  transform: rotate(-60deg);
  transform-origin: 50% 85%;
}

.three-body__dot:nth-child(2)::after {
  bottom: 0;
  left: 0;
  animation: wobble1 var(--uib-speed) infinite calc(var(--uib-speed) * -0.15) ease-in-out;
}

.three-body__dot:nth-child(3) {
  bottom: -5%;
  left: 0;
  transform: translateX(116.666%);
}

.three-body__dot:nth-child(3)::after {
  top: 0;
  left: 0;
  animation: wobble2 var(--uib-speed) infinite ease-in-out;
}


.info-container {
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 32px;
  margin-bottom: 32px;
}


@keyframes spin78236 {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

@keyframes wobble1 {
  0%, 100% {
    transform: translateY(0%) scale(1);
    opacity: 1;
  }
  50% {
    transform: translateY(-66%) scale(0.65);
    opacity: 0.8;
  }
}

@keyframes wobble2 {
  0%, 100% {
    transform: translateY(0%) scale(1);
    opacity: 1;
  }
  50% {
    transform: translateY(66%) scale(0.65);
    opacity: 0.8;
  }
}
@media (max-width: 1024px) {
  .satellite-details {
    margin: 20px auto;
    padding: 24px;
  }

  .satellite-details h2 {
    font-size: 2em;
  }

  .compass-section {
    width: 35%;
  }

  .compass-image {
    width: 180px;
    height: 180px;
  }
}

@media (max-width: 768px) {
  .satellite-details {
    padding: 16px;
    margin: 16px;
  }

  .main-content {
    flex-direction: column;
    gap: 24px;
  }

  .compass-section {
    width: 100%;
  }

  .compass-container {
    padding: 16px;
  }

  .satellite-info {
    width: 100%;
    padding: 16px;
  }

  .satellite-info p {
    font-size: 1.1em;
  }

  .info-pair {
    flex-direction: column;
    gap: 4px;
  }

  .info-label {
    min-width: unset;
  }

  .pass-summary {
    flex-direction: column;
    gap: 16px;
    padding: 16px;
  }

  .visual-passes-section {
    padding: 16px;
  }

  .pass-item {
    padding: 16px;
    margin: 12px 0;
  }

  .modal-map {
    width: 95%;
    height: 90vh;
    padding: 16px;
  }

  .info-container {
    flex-direction: column;
  }

  .compass-container {
    width: 100%;
  }
}

@media (max-width: 480px) {
  .satellite-details {
    padding: 12px;
    margin: 12px;
  }

  .satellite-details h2 {
    font-size: 1.6em;
  }

  .compass-image {
    width: 140px;
    height: 140px;
  }

  .satellite-info p {
    font-size: 1em;
  }

  .pass-header {
    font-size: 1.1em;
    padding: 10px 16px;
  }

  .info-value {
    font-size: 1em;
  }

  .map-btn {
    width: 100%;
    padding: 12px 20px;
    font-size: 1em;
  }

  .close-button {
    width: 32px;
    height: 32px;
    font-size: 20px;
  }
}

@media (max-width: 320px) {
  .satellite-details {
    margin: 16px auto;
    padding: 16px;
  }

  .satellite-details h2 {
    font-size: 1.6em;
  }

  .satellite-info p {
    font-size: 0.9em;
  }

  .map-btn {
    padding: 10px 20px;
    font-size: 0.9em;
  }

  .close-button {
    width: 28px;
    height: 28px;
    font-size: 18px;
  }
}

@media (max-width: 375px) {
  .satellite-details {
    padding: 16px;
    margin: 16px;
  }

  .satellite-details h2 {
    font-size: 1.8em;
  }

  .satellite-info p {
    font-size: 1em;
  }

  .map-btn {
    padding: 12px 24px;
    font-size: 1em;
  }

  .close-button {
    width: 30px;
    height: 30px;
    font-size: 20px;
  }
}
